<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].VITESSE_J_U12.FIRST"
      :images="images"
      :check="locale[lang].VITESSE_J_U12.CHECK"
      :semaine="locale[lang].VITESSE_J_U12.SEMAINE"
      :apres="locale[lang].VITESSE_J_U12.APRES"
      :exercices="locale[lang].VITESSE_J_U12.EXERCICES"
      :infoShop="locale[lang].VITESSE_J_U12.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/JeunesV grupo 187.png",
        "Cycles/JeunesV grupo 188.png",
        "Cycles/JeunesV grupo 189.png",
      ],
    };
  },
};
</script>

<style scoped>
#cycle >>> #first p {
  font-weight: 800;
}

#cycle >>> #first p span {
  font-weight: 500;
}
</style>
